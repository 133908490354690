// src/components/Sidebar.js

import React from 'react';
import './styles/sideBar.css';
import { NavLink } from 'react-router-dom';

const Sidebar = ({ isOpen, setActiveComponent, onLinkClick  }) => {
  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <ul className="sidebar-list">
        <li onClick={() => {setActiveComponent('home'); onLinkClick();}}>Dashboard</li>
        <li onClick={() => {setActiveComponent('submissions'); onLinkClick()}}>My Submissions</li>
        {/* <li className="nav-item">
          Sim Cards 
          <ul className="sublinks">
            <li onClick={() => {setActiveComponent('distributions'); onLinkClick()}}>Submit Serial </li>
           
          </ul>
        </li> */}
        <br/>
        <br/>
        {/* <li onClick={() => {setActiveComponent('agents'); onLinkClick()}}>Agents</li> */}
        <hr />
        <br/>
       
        <li onClick={() => {setActiveComponent('profile'); onLinkClick()}}>Profile</li>
        <li onClick={() => {setActiveComponent('settings'); onLinkClick()}}>Settings</li>
        <li>Log Out</li>
      </ul>
    </div>
  );
};

export default Sidebar;
