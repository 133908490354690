import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './styles/home.css';
import logo from './assets/logo_blue.svg';
import service from './assets/icons_service.png';
import wifi from './assets/icon_wifi.png';
import sales from './assets/icons_sales.png';
import hero from './assets/hero_img.png';
import playstore_icon from './assets/icon_playstore.svg';


import Header from './sections/header';
import Footer from './sections/footer';


const Homepage = () =>    {
    return(
    <div>
         <Header/>
        {/* hero section starts below */}
        <section className="hero-section">
        
        <div className="hero">
            <div className = "hero-text">
                <h2>We are the leading tech company in Kenya</h2>
                <p>We provide potent communication services both locally and across the border.</p>

                <hr style={{ width: '50%', backgroundColor:'#052560'}} />
                <p style={{fontStyle:'italic', textAlign:'center'}}>
                    Just for you!
                </p>
            </div>
           
            <div className="hero-image">
                <img src={hero} alt="Hero Image" />
            </div>
        </div>
      
      <div className="apps-download">
            <h4>Download Masma Comm Apps</h4>
            
            <div className="download-buttons">
                <div>
                <p>Masma Comm B.Agent app</p>
                <button class="btn">
                    <img src={playstore_icon} alt="Icon" class="icon"/>
                    <span class="text">Download</span>
                </button>
                </div>

                <div class="vertical-line"></div>

                <div>
                <p>Masma Comm B.Agent app</p>
                <button class="btn">
                    <img src={playstore_icon} alt="Icon" class="icon"/>
                    <span class="text">Download</span>
                </button>
                </div>

            </div>
       
      </div>
    
    </section>

        {/* Services section starts below */}
        <section className="services-section">
        <h2>Services</h2>
        <div className="services-container">

            <div className="service">
                <img src={service} alt="Support" />
                <h3>Support</h3>
                <p>We provide 24/7 online and offline support to our esteemed customers.</p>
            </div>

            <div className="service">
                <img src={wifi} alt="Connectivity" />
                <h3>Connectivity</h3>
                <p>Communication is made easier when you try our new technologies. Get connected today.</p>
            </div>
            
            <div className="service">
                <img src={sales} alt="Sales" />
                <h3>Sales</h3>
                <p>The best sales deals in the market are found in Masma stores. You’re welcome to shop with us.</p>
            </div>
        </div>
        </section>
        
         <Footer/>    
        </div>
       
    
       
    )
    
    
};

export default Homepage;