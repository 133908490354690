import React, { useState,useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import logo from '../assets/logo_blue.svg';
import { Box, Card, Grid, TextField, Typography, Button, IconButton, MenuItem, Link as MUILink } from '@mui/material';
import { styled } from '@mui/system';
import '../styles/signup.css';  
import axiosInstance from '../utils/config';
const bcrypt = require('bcryptjs');


//globa mui input styling
const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '50px',
    '& fieldset': {
      borderColor: '#e0e0e0',
      transition: 'border-color 0.5s ease',
    },
    '&:hover fieldset': {
      borderColor: '#00bfa5',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#052560',
    },
  },
  '& .MuiInputLabel-outlined': {
    color: '#00bfa5',
    transition: 'color 0.3s ease',
    '&.Mui-focused': {
      color: '#052560',
    },
  },
  marginBottom: '16px',
}));

const Signup = () => {
    const [options, setOptions] = useState([]);
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const[loading, setLoading] = useState(false);
    
    const [confirmPass, setConfirmPass] = useState('');
    

    const generateToken = () => {
        // Simple token generation example, replace with a secure method in production
        return Math.random().toString(36).substr(2, 12);
    };

       // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value, 
        }));
    };

/*prepare our form data for submitting*/
 const [userData, setFormData] = useState({
    username: '',
    ota: '',
    branch:'',
    phone: '',
    email: '',
    password: ''
  });

    const handleSubmit = async (event) => {
        event.preventDefault();
  

        console.log('User Data:', userData);
        
        if(userData.password == confirmPass){

                try {
                setLoading(true);
                const response = await axiosInstance.post('/agent_signup', userData); // Adjust the URL as necessary
                console.log('Response:', response.data);
                  alert(`You are now an 'Agent', please log in! `);
                  navigate('/agent_login');

                  } catch (error) {
                  console.error('There was an error signing up!', error);
                  // You can set an error message in state to display to the user
                  }

                  setLoading(false); 
          } else {
             setError("password does not match");
            console.error('password does not match');
          }
   
    };

      //load outlet options from the database using this  fn
          useEffect(() => {
              axiosInstance.get('/get_outlets')
          .then((response) => {
            // Filter the needed fields (id and outlet)
            const filteredOptions = response.data.map(item => ({
              id: item.id,
              outlet: item.outlet,
            }));
            setOptions(filteredOptions);
          })
          .catch((error) => {
            console.error('Error fetching dropdown data:', error);
          });
      }, []);

    return (
        <div className="">
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#fff',
      }}
    >
          
    <form sx={{width:'1000px'}} onSubmit={handleSubmit}>
       <Box  
          sx={{
            display: 'grid',
            gap: 1,  // spacing between items
            gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },  // single column on small, two on medium+
            Width: '1000px',
            padding: '20px 40px',
            borderRadius: '20px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            margin: '0 auto' 
          }}
        >
            <Typography variant="h4" sx={{ color: '#052560', fontWeight: '200', mb: 4, textAlign: 'center',gridColumn: 'span 2'  }}>
            Create Account
            </Typography>
             {error && <Typography color="error" sx={{ textAlign: 'center', marginBottom:'10px',gridColumn: 'span 2' }}>{error}</Typography>}
              
              <StyledTextField
                fullWidth
                label="Full Names"
                name="username"
                required
                variant="outlined"
                Value={userData.username}
                onChange={handleChange}
                sx={{ gridColumn: 'span 2'}}  // span across both columns
              />

              {/* Phone Number */}
              <StyledTextField
                margin="dense"
                fullWidth
                label="Phone"
                name = "phone"
                required
                variant="outlined"
                defaultValue={userData.phone}
                Value={userData.phone}
                onChange={handleChange}
                
              />

              {/* OTA/Agent/SSO Number */}
              <StyledTextField
                margin="dense"
                fullWidth
                label="OTA/Agent/SSO Number"
                name = "ota"
                variant="outlined"
                required
                defaultValue="+254 7123456789"
                value={userData.ota}
                onChange={handleChange}
               
               
              />

              {/* Email Address */}
              <StyledTextField
                margin="dense"
                name="email"
                fullWidth
                required
                label="Email"
                variant="outlined"
                value={userData.email}
                onChange={handleChange}
                
              />

              {/* Select Branch */}
                < StyledTextField

                      margin="dense"
                      label="Select Branch"
                      variant="outlined"
                      fullWidth
                      select
                      required
                      name="branch"
                      value={userData.branch}
                      onChange={handleChange}
                    
                    >
                      {options.map((option) => (
                        <MenuItem key={option.id} value={option.outlet}>
                          {option.outlet}
                        </MenuItem>
                      ))}
                    </StyledTextField>

              {/* Password */}
              <StyledTextField
                name= "password"
                margin="dense"
                fullWidth
                label="password"
                variant="outlined"
                type="password"
                required
                value={userData.password}
                onChange={handleChange}
                
              />
      

              {/* Confirm Password */}
              <StyledTextField
                fullWidth
                name = "confirmPassword"
                margin="dense"
                label="Confirm Password"
                variant="outlined"
                type="password"
                required
                onChange={(e) => setConfirmPass(e.target.value)}
                value={confirmPass}
               
              />
               <Button
            variant="contained"
            type="submit"
            disabled={loading}
            sx={{
              backgroundColor: '#001f72',
              color: '#fff',
              gridColumn: 'span 2' ,
              borderRadius: '25px',
              padding: '10px 50px',
              width: '100%',
              transition: 'background-color 0.8s ease',
              '&:hover': {
                backgroundColor: '#00bfa5',
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
              },
            }}
          >
            {loading ? 'Creating Account...' : 'Create Account'}
          </Button>
                  <Typography variant="p" sx={{ color: '#052560',gridColumn: 'span 2', mb: 4, textAlign: 'center' }}>
                Already have an account? <Link to = "/agent_login" style= {{textDecoration:'none',cursor:'pointer','&:hover': {
                color: '#00bfa5',
                
              },}} >
                Login</Link>
                </Typography>
             </Box> 
            </form>
               
   </Box>




            {/* <div className="signup-card">
                <div className="logo">
                    <img src={logo} alt="Masma Communication Limited" />
                </div>
                <h2>Sign Up</h2>
                <form className="signup-form" onSubmit={handleSubmit}>
                    <div className="input-group">
                        <input
                            type="text"
                            placeholder="Bidii Mwikali"
                            name="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <input
                            type="number"
                            placeholder="01123456789"
                            name="ota"
                            value={ota_agent_sso}
                            onChange={(e) =>setOTA(e.target.value)}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <input
                            type="number"
                            placeholder="+254 123456789"
                            name="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <input
                            type="email" // Use type="email" for email input
                            placeholder="Email Address"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <input
                            type="password"
                            placeholder="Password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit">Sign Up</button>
                </form>

                <div className="additional-options">
                    <p>Already have an account? <Link to="/">login here</Link></p>
                </div>
            </div> */}
        </div>
    );
};

export default Signup;