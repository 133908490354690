import React, { useEffect,useState,useContext } from 'react';
import StatCard from '../../admin-components/statcard';
import axiosInstance from '../../utils/config';
import {SupportAgentOutlined,StoreOutlined,PendingActions,Task,MenuOutlined} from '@mui/icons-material';
import SendIcon from '@mui/icons-material/Send';
import { TextField, Button, Typography, Box, InputAdornment , Link as MUILink } from '@mui/material';
import { styled } from '@mui/system';
import { AuthContext } from '../../utils/AuthContext';

import './styles/home.css'



const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '50px',
    '& fieldset': {
      borderColor: '#e0e0e0',
      transition: 'border-color 0.5s ease',
    },
    '&:hover fieldset': {
      borderColor: '#00bfa5',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#052560',
    },
  },
  '& .MuiInputLabel-outlined': {
    color: '#00bfa5',
    transition: 'color 0.3s ease',
    '&.Mui-focused': {
      color: '#052560',
    },
  },
  marginBottom: '20px',
}));

  
const Home= ({exportToexcel, setActiveComponent}) => {

  const { user} = useContext(AuthContext);
  const [serialNo, setSerialNo] = useState('');

 
   const [stats, setStats] = useState({
    pending:0,
    submitted:0

  
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sendError, setSendError] = useState(null);


  useEffect(() => {
    const fetchStats = async () => {
      if(user){
         
             try {
      
              const pendingResponse = await axiosInstance.get(`/p/serial_count`);
              const submittedResponse = await axiosInstance.get(`/s/serial_count`);

              setStats({
                pending: pendingResponse.data.count,
                submitted: submittedResponse.data.count
              });
              
              setLoading(false);
            } catch (error) {
              setError('Error fetching stats');
              setLoading(false);
            } finally {
                setLoading(false); 
            }
      
      }
     
    };
  
  const intervalId = setInterval(() => {
            fetchStats(); // Fetch data every 3 seconds
        }, 1000);

        return () => clearInterval(intervalId);
   
  }, [user]);


  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  //submit serial number 
const handleUpdate = async (event) => {
  event.preventDefault(); 
        try {
            const response = await axiosInstance.put('/u/update_serial', {
                serial: serialNo,
                status : 1,
                agentId: user.id, // Send user.id as agentId
                branch: user.branch, // Optional: send branch if needed
            });

            console.log(response.data.message); // Handle success
            setSerialNo('');
        } catch (error) {

            setSendError(error.response.data.message || 'Failed to send serial number')
            console.error('Error updating serial:', error.response ? error.response.data : error.message);
            setSerialNo('');
        }
    };

    return (

        <div>
        {/* Statistics card */}
        <section className='stats'>
        {/* <h1>Quick Stats</h1> */}
        <div className="stat-cards">
        
          <StatCard 
            title="SUBMITTED" 
            value= {stats.submitted}  
            description = "serial numbers submitted" 
            icon={<Task sx={{ fontSize: 60, color: 'green' }}/>} />

            <StatCard 
            title="PENDING" 
            value={stats.pending} 
            description =  {`Serials available at ${localStorage.getItem('shop')} today`} 
            icon={<PendingActions sx={{ fontSize: 60, color: 'orange' }}/>} />
            
          
        </div>
        </section>
         <section className="submit-section">
            <h1>Submit serial</h1>
            <div className = "submit-container">
                 
                <Box
                  sx={{
                    width: '100%',
                    backgroundColor: '#fff',
                    padding: '40px',
                    borderRadius: '20px',
                    alignItems: 'center',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                  }}
                  >
                  {/* <Typography variant="h4" sx={{ color: '#052560', fontWeight: 'bold', mb: 4, textAlign: 'center' }}>
                   
                  </Typography> */}

                 
                  <form onSubmit={handleUpdate} className='submit-form' >
                    <StyledTextField
                      label="enter serial number"
                      variant="outlined"
                      required
                      value={serialNo}
                      onChange={(e) => setSerialNo(e.target.value)}
                      placeholder="***** ***** ***** *****"
                      fullWidth
                    
                      inputProps={{ maxLength: 20 }}
                    />
                  

                   {sendError && <Typography color="error" sx={{ textAlign: 'center', marginBottom:'10px'}}>{sendError}</Typography>}

                    <Button
                      variant="contained"
                      type="submit"
                      startIcon={<SendIcon />}
                      disabled={serialNo.length !== 20 || loading}
                      
                      sx={{
                        backgroundColor: '#001f72',
                        color: '#fff',
                        borderRadius: '25px',
                        padding: '10px 50px',
                        width: { xs: '100%', md: '400px' },
                        transition: 'background-color 0.8s ease',
                        '&:hover': {
                          backgroundColor: '#00bfa5',
                          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
                        }, 
                        '& .MuiButton-startIcon': {
                                marginRight: '20px', // Add space between the icon and the text
                              },
                      }}
                    >
                      {loading ? 'sending...' : 'Send'}
                    </Button>
                  </form>
                </Box>
          
            </div>
         </section>
        {/* Table setions comes here
        <section className='tables-section'>
          <h1>Sumbmissions</h1>
          <button onClick={exportToexcel}>Export To excel</button>
           <div className="table-container">
                        <table>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Branch</th>
                              <th>Location</th>
                              <th>Manager</th>
                              <th>Contact</th>
                              <th>Date Added</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tableData.map((row, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.branch}</td>
                                <td>{row.location}</td>
                                <td>{row.manager}</td>
                                <td>{row.contact}</td>
                                <td>{row.dateAdded}</td>
                                <td>
                                  <span className="action-remove">remove</span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                <div className="view-more" onClick={() => setActiveComponent('outlets')}>View All</div>
              </div>    
        </section> */}
        </div>
    );


}

export default Home;