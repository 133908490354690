import React, { createContext, useState, useEffect } from 'react';
import axiosInstance from '../utils/config';

export const AuthContext = createContext();

// Create a provider component
export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(() => {
    const token = localStorage.getItem('token');
    const username = localStorage.getItem('username');
    const userid = localStorage.getItem('userid');
    const shop = localStorage.getItem('shop'); 


    return { token, username, userid, shop }; 
  });
  const [loading, setLoading] = useState(true);

  // Check for token and set user
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const username = localStorage.getItem('username'); // Retrieve username
      const userid = localStorage.getItem('userid'); // Retrieve userid
      const shop = localStorage.getItem('shop'); // Retrieve branch


      setUser({ token, userid, username, shop }); // Set user state with token, userid, branch and username
    }
    setLoading(false);
  }, []);

  // Login function
  const login = async (phoneOrOTA, password) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post('/agent_login', {
        phoneOrOTA,
        password,
      });
      const { token, username, id, branch } = response.data; // Assuming username is part of the response
      
      localStorage.setItem('shop', branch);
      localStorage.setItem('userid', id);
      localStorage.setItem('token', token);
      localStorage.setItem('username', username); // Store user att from response in local storage

      setUser({id, token, username,branch }); // Include user attributes in the user object
      setLoading(false);
      console.log(localStorage.getItem('username'));
    } catch (error) {
      if (!error.response) {
        throw new Error('Network error: Unable to reach the server. Please try again later.');
      }
      throw new Error(error.response.data.message || 'Login failed. Please check your credentials.');
     
    } finally {
      setLoading(false);
      }
   
    
  };

  // Logout function
  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userid');
    localStorage.removeItem('username');
    localStorage.removeItem('shop'); 
    localStorage.removeItem('activeComponent');
    setUser({ token: null,userid: null, username: null }); 
  };

  const value = { user, loading, login, logout };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
