import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import '../styles/header.css';
import logo from '../assets/logo_light_blue.svg';

const Header = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (

    <header className="header">
        
      <div className="logo">
          <img src={logo} alt="Masma Communications Limited" />
          <h1>Masma Communications Limited</h1>
      </div>
      <nav className={isMobileMenuOpen ? 'mobile-menu' : ''}>

       <ul className={isMobileMenuOpen ? 'nav-links-mobile' : 'nav-links'}>
            <li><a href="#home">Home</a></li>
            <li className="nav-item">
                Agent
                <ul className="sublinks">
                    <li><Link to="/agent_login">Agent Login</Link></li>
                    <li><Link to="/agent_signup">Agent Signup</Link></li>
                </ul>
            </li>
            <li><a href="#shop">Shop</a></li>
            <li><a href="#services">Services</a></li>
            <li><a href="#contact">Contact</a></li>
        </ul>
      </nav>
      <button className="menu-icon" onClick={toggleMobileMenu}>
        <div className="hamburger"></div>
        <div className="hamburger"></div>
        <div className="hamburger"></div>
      </button>
    </header>

  );
};

export default Header;
