import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import '../styles/footer.css';
import logo from '../assets/logo_light_blue.svg';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-info">
        <p>P.O.Box 84595-80100</p>
        <p>Mombasa, Kenya</p>
        <p>+254  793 435 318</p>
        <p>info@masmakyc.co.ke</p>
      </div>
      <div className="footer-copyright">
        <p>Masma Communications Ltd (c) 2024</p>
        <p><span style = {{fontStyle: 'italic'}}>from</span> Wootech Suits Company</p>
      </div>
    </footer>
  );
}

export default Footer;