import React, { useEffect,useState,useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Typography, Box,InputAdornment,Link as MUILink } from '@mui/material';
import { styled } from '@mui/system';
import * as XLSX from 'xlsx';
import {Search,Add,DeleteForever,Edit} from '@mui/icons-material';
import axiosInstance from '../../utils/config';
import { AuthContext } from '../../utils/AuthContext';

import './popups/styles/Popup.css';

import './styles/outlets.css';


const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '50px',
    '& fieldset': {
      borderColor: '#e0e0e0',
      transition: 'border-color 0.5s ease',
    },
    '&:hover fieldset': {
      borderColor: '#00bfa5',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#052560',
    },
  },
  '& .MuiInputLabel-outlined': {
    color: '#00bfa5',
    transition: 'color 0.3s ease',
    '&.Mui-focused': {
      color: '#052560',
    },
  },
  marginBottom: '20px',
}));


 
const Submissions= () => {

  //initialize Auth context with user object
  const { user} = useContext(AuthContext);
  
    /* PopUp open and Send data*/
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
   const [itemToDelete, setItemToDelete] = useState(null);


/*prepare our form data for submitting*/
 const [formData, setFormData] = useState({
    outlets_id:'',
    outlet: '',
    location: '',
    supervisor: '',
    contact: '',
  });

// Function to open the modal for adding a new record
  const handleClickOpen = () => {
        setFormData({ outlets_id: '', outlet: '', location: '', supervisor: '', contact: '' });
        setIsEditMode(false);
        setOpen(true);
  };

  // Function to open the modal for editing an existing record
  const handleOpen = (item) => {
        setFormData(item); // Populate form data with the selected item's data
        setIsEditMode(true); // Set edit mode    
        setOpen(true); // Open the modal
    };

  const handleClose = () => {
        setFormData({ outlets_id: '', outlet: '', location: '', supervisor: '', contact: '' }); // Reset form data
        setIsEditMode(false); // Reset edit mode
        setOpen(false);
  };



  // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value, // Update form data as user types
        }));
    };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
          if(isEditMode){
            console.log(formData);
            await axiosInstance.put(`update_outlet/${formData.outlets_id}`, formData);
             alert("outlet updated successfully!");

          } else {

              await axiosInstance.post('/new_outlet', formData);
              alert("Branch added successfully!");

          }
           
            handleClose();
          } catch (error) {
            console.error('There was an error adding the branch!', error);
          }
  };
   // Function to open the delete confirmation popup
    const handleDeleteOpen = (item) => {
        setItemToDelete(item); // Set the item to delete
        setDeleteOpen(true); // Open the delete confirmation modal
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false); // Close the delete confirmation modal
        setItemToDelete(null); // Reset the item to delete
    };

    const handleDelete = async () => {
        try {
            const response = await axiosInstance.delete(`delete_outlet/${itemToDelete.outlets_id}`); 
            console.log('outlet deleted:', response.data);
            alert('outlet deleted sucessfully!');
            handleDeleteClose(); // Close the confirmation modal
            // Optionally update your local state to remove the deleted item from the list
        } catch (error) {
            console.error('Error deleting record:', error);
        }
    };





    //search outlets
    const [data, setData] = useState([]); // Initialize with an empty array
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    // Fetch data from the API
    const fetchData = async () => {
        try {
            const response = await axiosInstance.get(`/s/get_serials`); // Adjust the endpoint as needed
            setData(response.data);
           
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // Use useEffect to fetch data on component mount
    useEffect(() => {
        fetchData();
        const intervalId = setInterval(() => {
            fetchData(); // Fetch data every 3 seconds
        }, 1000);

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, []);

    // Use useEffect to filter data based on the search term
    useEffect(() => {
        const results = data.filter(item =>
            Object.values(item).some(value =>
                value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
        setFilteredData(results);
    }, [searchTerm, data]);

     const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0]; 
    };


   // handle exports here
const exportToexcel = () =>{
  const worksheet = XLSX.utils.json_to_sheet(filteredData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'SIMCARDS');

  XLSX.writeFile(workbook, 'serial_number_submissions.xls');

 
};

//render content
    return (
        <div>
            <section className='tables-section'>
          <h1> My Submissions</h1>
          
         
                    <Box
                        sx={{
                        display: { xs: 'column', md: 'flex' },
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        width: '100%',
                        position: 'relative', 
                        zIndex: { xs: 0, md: 0 },
                        marginBottom:'10px'

                        }}
                        >
                        <StyledTextField
                            label="search"
                            variant="outlined"
                            placeholder="search serial"
                            sx={{
                                width: { xs: '100%', md: '400px' }, 
                                marginBottom: { xs: '16px', md: '0' }, 
                            }}
                           
                            slotProps={{
                                input: {
                                startAdornment: (
                                    <InputAdornment position="start">
                                    <Search/>
                                    </InputAdornment>
                                ),
                                },
                    
                            }}
                            
                            onChange = {(e) => setSearchTerm(e.target.value)} 
                            value = {searchTerm}
                            
                        />
                        
                        <Button
                            variant="contained"  
                            onClick={exportToexcel}
                            startIcon = {<Add  sx = {{fontSize:30}}/> }
                            sx={{
                            backgroundColor: 'green',
                            color: '#fff',
                            fontFamily:'Tilt Neon, sans-serif',
                            borderRadius: '25px',
                            padding: '10px 20px',
                            width: { xs: '100%', md: '200px' },
                            marginBottom: { xs: '16px', md: '0' },
                            transition: 'background-color 0.3s ease',
                            '&:hover': {
                                backgroundColor: '#00bfa5',
                                transition: 'background-color 0.3s ease',
                            },
                                '& .MuiButton-startIcon': {
                                marginRight: '8px', // Add space between the icon and the text
                                },
                            }}
                            
                        >
                           Export to Excel
                        </Button>
                        </Box>
           
      
          
           
           <div className="table-container">
                        <table>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Serial no.</th>
                              <th>Date</th>
                              <th>Time</th>
                              <th>Status</th>

                            </tr>
                          </thead>
                          <tbody>
                            {filteredData.length > 0 ? (filteredData.map((row, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.serial}</td>
                                <td>{formatDate(row.date_issued)}</td>
                                <td>{row.time_issued}</td>
                                <td>
                                  <span style={{color:'green'}}>sucess!</span>
                                </td>
                              </tr>
                            ))
                            ): (
                            <tr>
                            <td colSpan={7} style={{ textAlign: 'center' }}>
                                <Typography variant="body2" color="#052560">
                                    No records found!
                                </Typography>
                            </td>
                            </tr>
                        )}
                          </tbody>
                          
                        </table>
                        <p style={{fontWeight: 600, fontStyle:'bold', fontFamily:'Quicksand, sans-serif'}}>total submissions : {filteredData.length} </p>
               
              </div>    
        </section>
        
        {/* The Popup itself */}
      {open && (
       <div className="popup-overlay">
      <div className="popup-container">
        <div className="popup-header">
          <h3>{isEditMode ? 'Edit Branch' : 'Add Branch'}</h3>
          <button className="close-popup-btn" onClick={handleClose}>
            &times;
          </button>
        </div>

        <div className="popup-content">
          <form onSubmit={handleSubmit}>
            <StyledTextField
              margin="dense"
              name="outlet"
              label="Outlet"
              required
              fullWidth
              variant="outlined"
              value={formData.outlet}
              onChange={handleChange}
            />
            <StyledTextField
              margin="dense"
              name="location"
              label="Location"
              fullWidth
              required
              variant="outlined"
              value={formData.location}
              onChange={handleChange}
            />
            <StyledTextField
              margin="dense"
              name="supervisor"
              label="Supervisor"
              fullWidth
              variant="outlined"
              required
              value={formData.supervisor}
              onChange={handleChange}
            />
            <StyledTextField
              margin="dense"
              name="contact"
              label="Contact"
              fullWidth
              variant="outlined"
              required
              value={formData.contact}
              onChange={handleChange}
            />

            <div className="popup-actions">
              <button type="button" className="popup-btn" onClick={handleClose}>
                Cancel
              </button>
              <button type="submit" className="popup-btn">
                {isEditMode ? 'Save' : 'Add'}
              </button>

            </div>
          </form>
        </div>
      </div>
    </div>
      )}

      {/* delete Popup */}
        {deleteOpen && (
                <div className="popup-overlay">
                    <div className="popup-container">
                        <div className="popup-header">
                            <h3>Remove Item</h3>
                            <button className="close-popup-btn" onClick={handleDeleteClose}>
                                &times;
                            </button>
                        </div>

                        <div className="popup-content">
                            <p>Are you sure you want to delete <span style={{color:'orange'}}>'{itemToDelete?.outlet}'</span> permanently?</p>
                            <div className="popup-actions">
                                <button type="button" className="popup-btn" onClick={handleDeleteClose}>
                                    Cancel
                                </button>
                                <button type="button" className="popup-btn" onClick={handleDelete}>
                                    Yes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
      
    );
}

export default Submissions;